<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_seed.application') + ' ' + $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col lg="5" md="5" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('research_seed.seed_type')"
                label-for="seed_type_id"
                >
                <b-form-select
                    plain
                    id="seed_type_id"
                    :options="seedTypeList"
                    v-model="search.seed_type_id"
                    placeholder=""
                >
                    <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="5" md="5" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('research_seed.seed_name')"
                label-for="seed_name_id"
                >
                <b-form-select
                    plain
                    id="seed_name_id"
                    :options="filteredSeeds"
                    v-model="search.seed_name_id"
                    placeholder=""
                >
                    <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="5" md="5" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('research_seed.seed_class_name')"
                label-for="seed_class_id"
                >
                <b-form-select
                    plain
                    id="seed_class_id"
                    :options="seedClassList"
                    v-model="search.seed_class_id"
                    placeholder=""
                >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="5" md="5" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="from"
                >
                    <template v-slot:label>
                        {{$t('research_seed.from_date')}}
                    </template>
                    <b-form-input
                        class="date-picker"
                        id="from"
                        v-model="search.from"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col lg="5" md="5" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="to"
                >
                    <template v-slot:label>
                        {{$t('research_seed.to_date')}}
                    </template>
                    <b-form-input
                        class="date-picker"
                        id="to"
                        v-model="search.to"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col lg="5" md="5" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('research_seed.applicant_name')"
                label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="search.name"
                        placeholder=""
                    ></b-form-input>
                </b-form-group>
            </b-col>
          <b-col lg="2" md="2" sm="12" xs="12">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_seed.application') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="datas" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(applicationId)="data">
                      {{ $n(data.item.applicationId, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(created_at)="data">
                      <span>{{ data.item.created_at | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(applicant_name)="data">
                      <span class="capitalize">{{ data.item.applicant_name }}</span>
                    </template>
                    <template v-slot:cell(applicant_name_bn)="data">
                      {{ data.item.applicant_name_bn }}
                    </template>
                    <template v-slot:cell(applicant_org_name)="data">
                      <span class="capitalize">{{ data.item.applicant_org_name || getOrgName(data.item.govt_org_id) }}</span>
                    </template>
                    <template v-slot:cell(applicant_org_name_bn)="data">
                      <span class="capitalize">{{ data.item.applicant_org_name_bn || getOrgName(data.item.govt_org_id) }}</span>
                    </template>
                    <template v-slot:cell(seed_name)="data">
                      <span class="capitalize">{{ getSeedName(data.item.seed_name_id) }}</span>
                    </template>
                    <template v-slot:cell(seed_variety)="data">
                      <span class="capitalize">{{ getSeedVariety(data.item.seed_variety_id) }}</span>
                    </template>
                    <template v-slot:cell(seed_class)="data">
                      <span class="capitalize">{{ getSeedClass(data.item.seed_class_id) }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <b-badge class="cursor"  v-if="data.item.application_status === 6" variant=" iq-bg-success" size="sm" @click="remove(data.item)" title="Generate">Generate <i class="fa fa-paper-plane" aria-hidden="true"></i></b-badge>
                        <b-badge class="cursor" v-if="data.item.application_status >= 8" title="Certificate view" v-b-modal.modal-5 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-file-text-line m-0 "></i></b-badge>
                        <b-badge class="cursor" title="Application Details" v-b-modal.modal-6 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="view(data.item)"><i class="ri-eye-line m-0 "></i></b-badge>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-5" size="lg" :title="applicationDetails" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
            {{  $t('globalTrans.print') }}
        </b-button>
        <Details :id="editItemId" ref="details" />
    </b-modal>
      <b-modal id="modal-6" size="xl" :title="this.$t('research_seed.details_title')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <AllDetails :id="application" :key="application" />
      </b-modal>
  </b-container>
</template>
<script>
import Details from './DetailModal.vue'
import AllDetails from '../application-verification/Details'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { getCertificateList, getCertificateToggleStatus } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import researchTestingHelper from '@/mixins/agri-research-helpers'
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import Store from '@/store'
import moment from 'moment'
export default {
    components: {
        Details,
        AllDetails
    },
    mixins: [ModalBaseMasterList, researchTestingHelper],
    data () {
        return {
            filteredSeeds: [],
            remarks: false,
            datas: [],
            search: {
                name: '',
                seed_type_id: 0,
                seed_class_id: 0,
                from: '',
                to: '',
                seed_name_id: 0,
                org_id: 0,
                office_id: 0
            },
            application: {},
            action: '',
            applicationDetails: this.$t('research_seed.seed_certificate_info')
        }
    },
    watch: {
        'search.seed_type_id': function (newVal, oldVal) {
            this.filteredSeeds = this.getSeedList(newVal)
        }
    },
    created () {
    if (this.authUser.role_id === 1) {
    } else if (this.authUser.is_org_admin === 1) {
      this.search.org_id = this.authUser.org_id
    } else if (this.authUser.is_admin === true) {
      this.search.org_id = this.authUser.org_id
      this.search.office_id = this.authUser.office_id
    }
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        formTitle () {
            return (this.action === 'approve') ? this.$t('research_seed.approval_form') : this.$t('research_seed.rejection_form')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('research_seed.application_id'), class: 'text-left' },
                { label: this.$t('research_seed.application_date'), class: 'text-left' },
                { label: this.$t('research_seed.applicant_name'), class: 'text-left' },
                { label: this.$t('globalTrans.organization'), class: 'text-left' },
                { label: this.$t('research_seed.seed_name'), class: 'text-left' },
                { label: this.$t('research_seed.seed_variety_name'), class: 'text-left' },
                { label: this.$t('research_seed.seed_class_name'), class: 'text-left' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'applicationId' },
                { key: 'created_at' },
                { key: 'applicant_name_bn' },
                { key: 'applicant_org_name_bn' },
                { key: 'seed_name' },
                { key: 'seed_variety' },
                { key: 'seed_class' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'applicationId' },
                { key: 'created_at' },
                { key: 'applicant_name' },
                { key: 'applicant_org_name' },
                { key: 'seed_name' },
                { key: 'seed_variety' },
                { key: 'seed_class' },
                { key: 'action' }
                ]
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    mounted () {
        core.index()
        flatpickr('.date-picker', {})
        if (this.authUser.role_id === 0) {
            this.search = Object.assign({}, this.search)
        }
        this.loadData()
    },
    methods: {
        getOrgName (id) {
            if (id) {
                const tmpOrg = this.$store.state.commonObj.organizationProfileList.find(newItem => newItem.value === parseInt(id))
                if (tmpOrg) {
                if (this.$i18n.locale === 'en') {
                    return tmpOrg.text_en
                } else {
                    return tmpOrg.text_bn
                }
                } else {
                return ''
                }
            }
        },
        getDate (date) {
            return moment(date).format('L')
        },
        pdfExport () {
            this.$refs.details.pdfExport()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(agriResearchServiceBaseUrl, getCertificateList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                    const allData = this.$store.state.list
                    this.datas = JSON.parse(JSON.stringify(allData))
                } else {
                    this.$store.dispatch('setList', response.data.data)
                    this.datas = []
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        viewRemarks (item) {
            this.remarks = true
            this.application = item
        },
        view (item) {
            this.application = item.id
        },
        dataList (data) {
            const listData = data
            return listData
        },
        changeStatus (baseUrl, uri, item, destination = null, dropdownName = null) {
            Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
                if (response.success) {
                    if (destination !== null && dropdownName !== null) {
                        Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
                    }
                    // const notification = response.notification
                    // this.$socket.emit('send-notification', notification)
                        window.vm.$toast.success({
                        title: 'Success',
                        message: 'Data Submitted Successfully',
                        color: '#D6E09B'
                    })
                } else {
                    window.vm.$toast.error({
                        title: 'Error',
                        message: 'Operation failed! Please, try again.'
                    })
                }
                Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
            })
        },
        remove (item) {
                window.vm.$swal({
                title: window.vm.$t('globalTrans.publishMsg'),
                showCancelButton: true,
                confirmButtonText: window.vm.$t('globalTrans.yes'),
                cancelButtonText: window.vm.$t('globalTrans.no'),
                focusConfirm: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.changeStatus(agriResearchServiceBaseUrl, getCertificateToggleStatus, item)
                }
            })
        }
    }
}
</script>
<style scoped>
  .cursor{
    cursor: pointer;
  }
</style>
