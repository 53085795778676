
// Seed Type Starts
export const seedTypeList = '/master-seed-type/list'
export const seedTypeStore = '/master-seed-type/store'
export const seedTypeUpdate = '/master-seed-type/update'
export const seedTypeToggleStatus = '/master-seed-type/toggle-status'
export const seedTypeDestroy = '/master-seed-type/destroy'

// Seed List Starts
export const seedNameList = '/master-seed-name/list'
export const seedNameStore = '/master-seed-name/store'
export const seedNameUpdate = '/master-seed-name/update'
export const seedNameToggleStatus = '/master-seed-name/toggle-status'
export const seedNameDestroy = '/master-seed-name/destroy'

// Seed Class Starts
export const seedClassList = '/master-seed-class/list'
export const seedClassStore = '/master-seed-class/store'
export const seedClassUpdate = '/master-seed-class/update'
export const seedClassToggleStatus = '/master-seed-class/toggle-status'
export const seedClassDestroy = '/master-seed-class/destroy'

// Quality Unit Starts
export const qualityUnitList = '/master-quality-unit/list'
export const qualityUnitStore = '/master-quality-unit/store'
export const qualityUnitUpdate = '/master-quality-unit/update'
export const qualityUnitToggleStatus = '/master-quality-unit/toggle-status'
export const qualityUnitDestroy = '/master-quality-unit/destroy'

// Production Season Starts
export const prodSeasonList = '/master-production-season/list'
export const prodSeasonStore = '/master-production-season/store'
export const prodSeasonUpdate = '/master-production-season/update'
export const prodSeasonToggleStatus = '/master-production-season/toggle-status'
export const prodSeasonDestroy = '/master-production-season/destroy'

// Seed Variety Starts
export const seedVarietyList = '/master-seed-variety/list'
export const seedVarietyStore = '/master-seed-variety/store'
export const seedVarietyUpdate = '/master-seed-variety/update'
export const seedVarietyToggleStatus = '/master-seed-variety/toggle-status'
export const seedVarietyDestroy = '/master-seed-variety/destroy'

// Seed Packet Starts
export const packetList = '/master-seed-packet/list'
export const packetStore = '/master-seed-packet/store'
export const packetUpdate = '/master-seed-packet/update'
export const packetToggleStatus = '/master-seed-packet/toggle-status'
export const packetDestroy = '/master-seed-packet/destroy'

// Inspection Schedule Starts
export const inspectionScheduleList = '/master-inspection-schedule/list'
export const inspectionScheduleStore = '/master-inspection-schedule/store'
export const inspectionScheduleUpdate = '/master-inspection-schedule/update'
export const inspectionScheduleToggleStatus = '/master-inspection-schedule/toggle-status'
export const inspectionScheduleDestroy = '/master-inspection-schedule/destroy'

// Seed Quality Starts
export const qualityList = '/master-quality/list'
export const qualityStore = '/master-quality/store'
export const qualityUpdate = '/master-quality/update'
export const qualityToggleStatus = '/master-quality/toggle-status'
export const qualityDestroy = '/master-quality-setup/destroy'

// Seed Quality Starts
export const measurementList = '/master-quality-measurements/list'
export const measurementStore = '/master-quality-measurements/store'
export const measurementUpdate = '/master-quality-measurements/update'
export const measurementToggleStatus = '/master-quality-measurements/toggle-status'
export const measurementDestroy = '/master-quality-measurements/destroy'

// Fee Policy Starts
export const feePolicyList = '/master-fee-policy/list'
export const feePolicyStore = '/master-fee-policy/store'
export const feePolicyUpdate = '/master-fee-policy/update'
export const feePolicyToggleStatus = '/master-fee-policy/toggle-status'
export const feePolicyDestroy = '/master-fee-policy/destroy'

// Service Info Starts
export const serviceInfoList = '/master-service-info/list'
export const serviceInfoStore = '/master-service-info/store'
export const serviceInfoUpdate = '/master-service-info/update'
export const serviceInfoToggleStatus = '/master-service-info/toggle-status'
export const serviceInfoDestroy = '/master-service-info/destroy'

// Report Heading
export const reportHeadingList = '/report-heading/list'
export const reportHeadingStore = '/report-heading/store'
export const reportHeadingUpdate = '/report-heading/update'
export const reportHeadingToggleStatus = '/report-heading/toggle-status'

// Applicant List Starts
export const applicantList = '/master-seed-certification/sorting-application/list'
export const applicantApproveStatus = '/master-seed-certification/sorting-application/status'
export const applicationVerificationShow = '/master-seed-certification/sorting-application/show'
export const applicantStatusGet = '/master-seed-certification/sorting-application/not-approved'
export const applicanUpdate = '/master-service-info/update'
export const applicanToggleStatus = '/master-service-info/toggle-status'
export const applicanDestroy = '/master-service-info/destroy'

// Application Info Starts
export const getSingleApplication = '/master-seed-certification/citizen-profile/general-info'

// Inspection Schedule Confirmation Starts
export const inspectionVerifiedList = '/master-seed-certification/inspection-schedule/list'
export const inspectionVerifiedAdd = '/master-seed-certification/inspection-schedule/status'
export const inspectionVerifiedGet = '/master-seed-certification/inspection-schedule/single'

// Inspection Report Submit Starts
export const inspectionReportList = '/master-seed-certification/inspection-report/list'
export const inspectionReportSecondList = '/master-seed-certification/inspection-report/second-list'
export const inspectionReportThirdList = '/master-seed-certification/inspection-report/third-list'
export const inspectionReportStore = '/master-seed-certification/inspection-report/store'
export const inspectionOneShow = '/master-seed-certification/inspection-report/show'
export const inspectionTwoShow = '/master-seed-certification/inspection-report/second-show'
export const inspectionThirdShow = '/master-seed-certification/inspection-report/third-show'
export const inspectionReportGet = '/master-seed-certification/inspection-report/single'
export const officerRemove = '/master-seed-certification/inspection-report/officer-remove'
export const NumberOfTreeRemove = '/master-seed-certification/inspection-report/numberoftreeremove'
export const NumberOfTreesStore = '/master-seed-certification/inspection-report/numberoftreestore'

// User List
export const getUserList = '/user/user-list'

// Application Approval And Rejection Starts
export const getListForFilter = '/master-seed-certification/application-approval-rejection/list'
export const getApproved = '/master-seed-certification/application-approval-rejection/approved'
export const getRejected = '/master-seed-certification/application-approval-rejection/rejected'
export const storeRemark = '/master-seed-certification/application-approval-rejection/store'

// Application Certificate List Starts
export const getCertificateList = '/master-seed-certification/field-certificate/list'
export const getCertificateToggleStatus = '/master-seed-certification/field-certificate/toggle-status'
export const getCertificate = '/master-seed-certification/field-certificate/view'

// Seed Certification Dashboard
// export const dashboard = '/agri-research-service/seed-certification/dashboard'
export const seedCertificatonDashboard = '/seed-certification/seed-certification-dashboard'

// application report data
export const getApplicationData = '/seed-certification/seed-certification-report'
export const getReportData = '/seed-certification/report-under-field-certificate'
export const getOrgWiseApplicationData = '/seed-certification/org-wise-seed-report'
export const getOrgWiseLandApplicationData = '/seed-certification/org-wise-land-report'

// application org
export const getApplicationOrg = '/seed-certification/application-org'

// seed producer setup
export const producerList = '/master-seed-producer/list'
export const producerStore = '/master-seed-producer/store'
export const producerUpdate = '/master-seed-producer/update'
export const producerStatusChange = '/master-seed-producer/toggle-status'

// seed processing center setup
export const processingCenterList = '/master-seed-processing-center/list'
export const processingCenterStore = '/master-seed-processing-center/store'
export const processingCenterUpdate = '/master-seed-processing-center/update'
export const processingCenterStatusChange = '/master-seed-processing-center/toggle-status'

// seed scheme setup
export const seedSchemeList = '/master-seed-scheme/list'
export const seedSchemeStore = '/master-seed-scheme/store'
export const seedSchemeUpdate = '/master-seed-scheme/update'
export const seedSchemeStatusChange = '/master-seed-scheme/toggle-status'
export const getPaymentReceipt = '/testing-tag-request-without-certification/paymentReceipt'

export const nothiCertificate = '/nothi/certificate-sent-nothi'

export const fetchTotal = ''
