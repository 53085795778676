<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <div style="min-height: 200px ;">
                                <b-row v-if="!loading">
                                    <b-col md='12' v-if="details.organization_address">
                                        <div style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('application_details.organization_information')}}</h5></div>
                                        <b-table-simple striped bordered small hover>
                                            <tbody>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.dealer_registration_number')}}</b-th>
                                                <b-td style="width: 25% !important;"> {{details.organization_address.dealer_registration_number}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.dealer_registration_date')}}</b-th>
                                                <b-td style="width: 25% !important;"> {{details.organization_address.dealer_registration_date}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.organization_name')}}</b-th>
                                                <b-td style="width: 25% !important;">{{getOrgName(details.govt_org_id) || LangCheck(details.applicant_org_name_bn,details.applicant_org_name)}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.organization_type')}}</b-th>
                                                <b-td style="width: 25% !important;">{{checkOrg(details.org_type)}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.organization_address')}}</b-th>
                                                <b-td style="width: 25% !important;"><span v-if="details.organization_address">{{addreassMaker(details.organization_address)}}</span></b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.phone')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.organization_address.mobile_phone_no}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('external_research.office_type')}}</b-th>
                                                <b-td style="width: 25% !important;">{{getOfficeType(details.organization_address.office_type_id)}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('certify_form.office_name')}}</b-th>
                                                <b-td style="width: 25% !important;">{{ getOfficeName(details.organization_address.office_id) }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.email')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.organization_address.email_address}}</b-td>
                                                <b-th></b-th>
                                                <b-td></b-td>
                                            </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                    </b-col>
                                    <b-col md='12' v-if="details.applicant_address">
                                        <div style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('application_details.applicant_s_address')}}</h5></div>
                                        <b-table-simple striped bordered small hover>
                                            <tbody>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.applicant_name')}}</b-th>
                                                <b-td style="width: 25% !important;">{{LangCheck(details.applicant_address.applicant_name_bn,details.applicant_address.applicant_name)}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.phone')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.applicant_address.a_mobile_phone_no}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.address')}}</b-th>
                                                <b-td style="width: 25% !important;">{{addreassMaker2(details.applicant_address)}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.email')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.applicant_address.a_email_address}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.signature')}}</b-th>
                                                <b-td style="width: 25% !important;">
                                                    <a :href="agriResearchServiceBaseUrl + 'storage/uploads/research_farmers/original/' + details.applicant_address.signature" target="_blank">
                                                        {{ $t('research_seed.download') }}
                                                        <i class="ri-download-cloud-line" ></i>
                                                    </a>
                                                </b-td>
                                            </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                    </b-col>
                                    <b-col md='12' v-if="details.plot_location">
                                        <div style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('application_details.plot_details')}}</h5></div>
                                        <b-table-simple striped bordered small hover>
                                            <tbody>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.mouja_scheme_name')}}</b-th>
                                                <b-td style="width: 25% !important;">{{getScheme(details.plot_location.scheme_id)}}</b-td>
                                            </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                    </b-col>
                                    <b-col md='12' v-if="details.seed_source">
                                        <div style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('application_details.seed_source')}}</h5></div>
                                        <b-table-simple striped bordered small hover>
                                            <tbody>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_certification_tag_number')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.seed_source.seed_certification_tag_number}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_type')}}</b-th>
                                                <b-td style="width: 25% !important;">{{seedType(details.seed_source.seed_type_id)}}</b-td>
                                            </b-tr> <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_name')}}</b-th>
                                                <!-- <b-td style="width: 25% !important;">{{seedName(details.seed_source.seed_name_id)}}</b-td> -->
                                                <b-td v-if="details.seed_source.seed_type_id == 8" style="width: 25% !important;">{{ details.seed_source.seed_name_others }}</b-td>
                                                <b-td v-if="details.seed_source.seed_type_id != 8" style="width: 25% !important;">{{ seedName(details.seed_source.seed_name_) }}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_variety')}}</b-th>
                                                <b-td v-if="details.seed_source.seed_type_id != 8" style="width: 25% !important;">{{seedVariety(details.seed_source.seed_variety_id)}}</b-td>
                                                <b-td v-if="details.seed_source.seed_type_id == 8" style="width: 25% !important;">{{ details.seed_source.seed_variety_others }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_class')}}</b-th>
                                                <b-td style="width: 25% !important;">{{seedClass(details.seed_source.seed_class_id)}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.lot_number')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.seed_source.lot_number}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_source_name')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.seed_source.seed_source_name}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.certificate_issue_date')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.seed_source.certificate_issue_date}}</b-td>
                                            </b-tr> <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.validity_period')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.seed_source.validity_period}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.net_weight_of_seed')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.seed_source.net_weight_seed}} {{Unit(details.seed_source.unit_id)}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_producer_name')}}</b-th>
                                                <b-td style="width: 25% !important;">{{LangCheck(details.seed_source.seed_producer_name_bn,details.seed_source.seed_producer_name)}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_producer_address')}}</b-th>
                                                <b-td style="width: 25% !important;">{{LangCheck(details.seed_source.seed_producer_address_bn,details.seed_source.seed_producer_address)}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.sowing_planting_date')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.seed_source.sowing_planting}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.upload_photo')}}</b-th>
                                                <b-td style="width: 25% !important;">
                                                    <a :href="agriResearchServiceBaseUrl + 'storage/uploads/research_farmers/' + details.seed_source.upload_photo" target="_blank">
                                                        {{ $t('research_seed.download') }}
                                                        <i class="ri-download-cloud-line" ></i>
                                                    </a>
                                                </b-td>
                                            </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                    </b-col>
                                    <b-col md='12' v-if="details.plot_location">
                                        <div style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('application_details.plot_field_and_seed_variety_details')}}</h5></div>
                                        <b-table-simple striped bordered small hover>
                                            <thead>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.plot_number')}}</b-th>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_variety')}}</b-th>
                                                <b-th style="width: 25% !important;">{{$t('application_details.amount_of_land')}}</b-th>
                                                <b-th style="width: 25% !important;">{{$t('application_details.date_of_harvest')}}</b-th>
                                            </b-tr>
                                            </thead>
                                            <tbody :key="index" v-for="(item, index)  in details.plot_location.plot_details">
                                            <b-tr>
                                                <b-td style="width: 25% !important;">{{item.plot_number}}</b-td>
                                                <b-td style="width: 25% !important;">{{seedVariety(item.seed_variety_id)}}</b-td>
                                                <b-td style="width: 25% !important;">{{item.amount_of_land}} {{ $t('research_seed.hectare')}}</b-td>
                                                <b-td style="width: 25% !important;">{{item.date_of_harvest}}</b-td>
                                            </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                    </b-col>
                                    <b-col md='12' v-if="details.before_showing_planting">
                                        <div style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('application_details.before_sowing_planting')}}</h5></div>
                                        <b-table-simple striped bordered small hover>
                                            <tbody>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.production_year')}}</b-th>
                                                <b-td style="width: 25% !important;">{{ details.before_showing_planting.production_year }}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.area_name')}}</b-th>
                                                <b-td style="width: 25% !important;">{{LangCheck(details.before_showing_planting.seed_producer_name_bn,details.before_showing_planting.seed_producer_name)}}</b-td>
                                            </b-tr> <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.block_sub_area_name')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.before_showing_planting.block_sub_area}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.production_season')}}</b-th>
                                                <b-td style="width: 25% !important;">{{productionSeason(details.before_showing_planting.production_season_id)}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_production_area')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.before_showing_planting.seed_production_area}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.certificate_issue_date')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.before_showing_planting.certificate_issue_date}} </b-td>
                                            </b-tr>
                                            <b-tr>

                                                <b-th style="width: 25% !important;">{{$t('application_details.amount_of_seed_production')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.before_showing_planting.amount_seed_production}} {{Unit (details.before_showing_planting.b_unit_id)}}</b-td>
                                            </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                    </b-col>
                                    <b-col md='12' v-if="details.document">
                                        <div v-if="details.document.dealer_reg_certificate" style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('application_details.source_tag_doc')}}</h5></div>
                                        <b-table-simple striped bordered small hover>
                                            <tbody>
                                                <b-tr>
                                                    <b-th style="width: 25% !important;" v-if="details.document.dealer_reg_certificate">{{$t('application_details.dealer_reg_certificate')}}</b-th>
                                                    <b-td style="width: 25% !important;" v-if="details.document.dealer_reg_certificate">
                                                        <a :href="agriResearchServiceBaseUrl + 'storage/uploads/research_farmers/' + details.document.dealer_reg_certificate" target="_blank">
                                                            {{ $t('research_seed.download') }}
                                                            <i class="ri-download-cloud-line" ></i>
                                                        </a>
                                                    </b-td>
                                                    <b-th style="width: 25% !important;" v-if="details.document.mouja_map">{{$t('application_details.mouja_map')}}</b-th>
                                                    <b-td style="width: 25% !important;" v-if="details.document.mouja_map">
                                                        <a :href="agriResearchServiceBaseUrl + 'storage/uploads/research_farmers/' + details.document.mouja_map" target="_blank">
                                                            {{ $t('research_seed.download') }}
                                                            <i class="ri-download-cloud-line" ></i>
                                                        </a>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th style="width: 25% !important;" v-if="details.document.seed_purchase_receipt">{{$t('application_details.seed_purchase_receipt')}}</b-th>
                                                    <b-td style="width: 25% !important;" v-if="details.document.seed_purchase_receipt">
                                                        <a :href="agriResearchServiceBaseUrl + 'storage/uploads/research_farmers/' + details.document.seed_purchase_receipt" target="_blank">
                                                            {{ $t('research_seed.download') }}
                                                            <i class="ri-download-cloud-line" ></i>
                                                        </a>
                                                    </b-td>
                                                    <b-th style="width: 25% !important;" v-if="details.document.source_tag_doc">{{$t('application_details.source_tag_doc')}}</b-th>
                                                    <b-td style="width: 25% !important;" v-if="details.document.source_tag_doc">
                                                         <a :href="agriResearchServiceBaseUrl + 'storage/uploads/research_farmers/' + details.document.source_tag_doc" target="_blank">
                                                            {{ $t('research_seed.download') }}
                                                            <i class="ri-download-cloud-line" ></i>
                                                        </a>
                                                    </b-td>
                                                </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                    </b-col>
                                    <b-col md='12' v-if="details.document">
                                    <div  v-if="details.document.document_details.lenght > 0">
                                        <div style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('application_details.doc_details')}}</h5></div>
                                        <b-table-simple striped bordered small hover>
                                            <tbody v-for="(item, index) in details.document.document_details" :key="index">
                                                <b-tr>
                                                    <b-th style="width: 25% !important;">{{$t('application_details.doc_name')}}</b-th>
                                                    <b-td style="width: 25% !important;">{{item.document_name}}</b-td>
                                                    <b-th style="width: 25% !important;">{{$t('globalTrans.attachment')}}</b-th>
                                                    <b-td style="width: 25% !important;">
                                                        <a :href="agriResearchServiceBaseUrl + 'storage/uploads/research_farmers/' + item.attachment" target="_blank">
                                                            {{ $t('research_seed.download') }}
                                                            <i class="ri-download-cloud-line" ></i>
                                                        </a>
                                                    </b-td>
                                                </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                    </div>
                                    </b-col>
                                    <b-col md='12' v-if="details.payment">
                                        <div style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('application_details.payment')}}</h5></div>
                                        <b-table-simple striped bordered small hover>
                                            <tbody>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.payment_status')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.payment.payment_status === 1 ? 'Bank' : 'EkPay'}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.deposited_amount')}}</b-th>
                                                <b-td style="width: 25% !important;"><span v-if="details.payment.payment_status === 1">{{details.payment.amount}}</span> <span v-else>{{details.payment.ekpay_deposited_amount}}</span></b-td>
                                            </b-tr>
                                            <b-tr v-if="details.payment.payment_status === 1">
                                                <b-th style="width: 25% !important;">{{$t('application_details.area_name')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.payment.treasury_challan_code}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.treasury_challan_file')}}</b-th>
                                                <b-td style="width: 25% !important;">
                                                <b v-if="details.payment.treasury_challan_file">
                                                <a :href="agriResearchServiceBaseUrl + 'storage/uploads/research_farmers/original/' + details.payment.treasury_challan_file" target="_blank">
                                                {{ $t('research_seed.download') }}
                                                <i class="ri-download-cloud-line" ></i>
                                                </a>
                                                </b>
                                                </b-td>
                                            </b-tr>
                                            <b-tr v-if="details.payment.payment_status === 1">
                                                <b-th style="width: 25% !important;">{{$t('application_details.treasury_challan_number')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.payment.treasury_challan_number}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.treasury_challan_date')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.payment.treasury_challan_date}}</b-td>
                                            </b-tr>
                                            <b-tr v-if="details.payment.payment_status === 1">
                                                <b-th style="width: 25% !important;">{{$t('application_details.bank_name')}}</b-th>
                                                <b-td style="width: 25% !important;">{{getBankName(details.payment.bank_id)}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.branch_name')}}</b-th>
                                                <b-td style="width: 25% !important;">{{getBranchName(details.payment.branch_id)}}</b-td>
                                            </b-tr>
                                            <b-tr v-if="details.payment.payment_status === 2">
                                                <b-th style="width: 25% !important;">{{$t('application_details.ekpay_transaction_number')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.payment.ekpay_transaction_number}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.ekpay_transaction_date')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.payment.ekpay_transaction_date}}</b-td>
                                            </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                    </b-col>
                                    <b-col md='12' v-if="details.showingplanting">
                                        <div style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('application_details.after_sowing_and_planting')}}</h5></div>
                                        <b-table-simple striped bordered small hover>
                                            <tbody>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.amount_of_seed_production')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.showingplanting.amount}} {{Unit(details.showingplanting.unit_id)}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_production_area')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.showingplanting.seed_production_area}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.planting_date')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.showingplanting.planting_date}}</b-td>
                                            </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                    </b-col>
                                    <b-col md='12' v-if="details.flowers">
                                        <div style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('application_details.after_flowers_come')}}</h5></div>
                                        <b-table-simple striped bordered small hover>
                                            <tbody>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.amount_of_seed_production')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.flowers.possible_seed_receipt}} {{Unit(details.flowers.unit_id)}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_production_area')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.flowers.seed_production_area}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.possible_date_of_harvesting')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.flowers.possible_date_harvesting}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.haulm_pulling_potato_date')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.flowers.haulm_pulling_potato_date}}</b-td>
                                            </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                    </b-col>
                                    <b-col md='12' v-if="details.harvesting">
                                        <div style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('application_details.harvesting')}}</h5></div>
                                        <b-table-simple striped bordered small hover>
                                            <tbody>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_processing_center_name')}}</b-th>
                                                <b-td style="width: 25% !important;">{{seedProcessingCentersList(details.harvesting.center_id)}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_storage_place')}}</b-th>
                                                <b-td style="width: 25% !important;">{{LangCheck(details.harvesting.seed_storage_place,details.harvesting.seed_storage_place)}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.production_year')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.before_showing_planting.production_year}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_name')}}</b-th>
                                                <b-td style="width: 25% !important;">{{seedName(details.seed_source.seed_name_id)}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.seed_variety')}}</b-th>
                                                <b-td style="width: 25% !important;">{{seedVariety(details.seed_source.seed_variety_id)}}</b-td>
                                               <b-th style="width: 25% !important;">{{$t('application_details.seed_amount_in_lot')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.harvesting.seed_amount_in_lot}}  {{Unit(details.harvesting.lot_unit_measurement)}}</b-td>
                                            </b-tr>
                                          <!--  <b-tr>
                                                <b-th style="width: 25% !important;">{{$t('application_details.the_amount_of_seeds_per_certification_tag')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.harvesting.amount_seeds_per_certification_tag}}</b-td>
                                                <b-th style="width: 25% !important;">{{$t('application_details.total_demand_of_certification_work')}}</b-th>
                                                <b-td style="width: 25% !important;">{{details.harvesting.total_demand_certification_work}}</b-td>
                                            </b-tr>-->
                                            </tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </div>
                            <div>
                                <b-row>
                                <b-col md='12' v-if="details.lot_main">
                                        <b-table-simple striped bordered small hover>
                                            <tbody>
                                                <b-tr>
                                                    <b-th style="width: 25%">{{$t('research_seed.total_packet')}}</b-th>
                                                    <b-th style="width: 25%">{{details.lot_main.total_packet}}</b-th>
                                                    <b-th style="width: 25%">{{$t('application_details.net_weight_of_seed')}}</b-th>
                                                    <b-th style="width: 25%">{{details.lot_main.total_seed_amount}}</b-th>
                                                    <!-- <b-th colspan="4"></b-th> -->
                                                </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                        <b-table-simple striped bordered small hover>
                                            <tbody>
                                                <b-tr v-for="(item, index) in details.lot_main.lot_offer" :key="index">
                                                    <b-th>{{$t('collection.lot_no')}}</b-th>
                                                    <b-th>{{item.lot_no}}</b-th>
                                                    <b-th>{{$t('application_details.seed_amount_in_lot')}}</b-th>
                                                    <b-th>{{item.seed_amount}}</b-th>
                                                    <b-tr v-for="(item1, index) in item.lot_offer_details" :key="index">
                                                        <b-th style="width: 25%">{{$t('application_details.packet_select')}}</b-th>
                                                        <b-th style="width: 25%">{{getPacket(item1.packet_select)}}</b-th>
                                                        <b-th style="width: 25%">{{$t('application_details.demand_packet_number')}}</b-th>
                                                        <b-th style="width: 25%">{{item1.demand_packet}}</b-th>
                                                    </b-tr>
                                                </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
    import { core } from '@/config/pluginInit'
    import flatpickr from 'flatpickr'
    import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
    import { getSingleApplication } from '../../../../../external-user-service/agri-research/api/routes'
    // import ExportPdf from './export_pdf_details'

    export default {
        components: {
        },
        props: ['id'],
        async created () {
            this.getApplication()
        },
        mounted () {
            core.index()
            flatpickr('.fromDate', {})
        },
        data () {
            return {
                agriResearchServiceBaseUrl,
                details: [],
                loading: false
            }
        },
        methods: {
                getBankName (id) {
                    const Obj = this.$store.state.commonObj.bankObj.bankList.find(item => item.value === parseInt(id))
                    if (typeof Obj === 'undefined') {
                        return ''
                    } else {
                        if (this.$i18n.locale === 'en') {
                            return Obj.text_en
                        } else {
                            return Obj.text_bn
                        }
                    }
                },
                getBranchName (id) {
                    const Obj = this.$store.state.commonObj.bankObj.branchList.find(item => item.value === parseInt(id))
                    if (typeof Obj === 'undefined') {
                        return ''
                    } else {
                        if (this.$i18n.locale === 'en') {
                            return Obj.text_en
                        } else {
                            return Obj.text_bn
                        }
                    }
                },
            getPacket (id) {
                const packet = this.$store.state.AgriResearch.commonObj.seedPacketList.find(item => item.value === parseInt(id)) || this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedPacketList.find(item => item.value === parseInt(id))
                return this.$i18n.locale === 'en' ? packet !== undefined ? packet.text_en : '' : packet !== undefined ? packet.text_bn : ''
            },
            Unit (id) {
                const seedUnit = this.$store.state.AgriResearch.commonObj.qualityUnitList.find(item => item.value === parseInt(id)) || this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.find(item => item.value === parseInt(id))
                return seedUnit !== undefined ? this.$i18n.locale === 'en' ? seedUnit.text_en : seedUnit.text_bn : ''
            },
            seedVariety (id) {
                const varity = this.$store.state.AgriResearch.commonObj.seedVarietyList.find(item => item.value === parseInt(id)) || this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedVarietyList.find(item => item.value === parseInt(id))
                return varity !== undefined ? this.$i18n.locale === 'en' ? varity.text_en : varity.text_bn : ''
            },
            seedClass (id) {
                const seedclass = this.$store.state.AgriResearch.commonObj.seedClassNameList.find(item => item.value === parseInt(id)) || this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedClassNameList.find(item => item.value === parseInt(id))
                return seedclass !== undefined ? this.$i18n.locale === 'en' ? seedclass.text_en : seedclass.text_bn : ''
            },
            seedName (id) {
                const seed = this.$store.state.AgriResearch.commonObj.seedNameList.find(item => item.value === parseInt(id)) || this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedNameList.find(item => item.value === parseInt(id))
                if (this.$i18n.locale === 'en') {
                    return seed !== undefined ? seed.text_en : ''
                } else {
                    return seed !== undefined ? seed.text_bn : ''
                }
            },
            seedType (id) {
                const types = this.$store.state.AgriResearch.commonObj.seedTypeNameList.find(item => item.value === parseInt(id)) || this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedTypeNameList.find(item => item.value === parseInt(id))
                if (this.$i18n.locale === 'en') {
                    return types !== undefined ? types.text_en : ''
                } else {
                    return types !== undefined ? types.text_bn : ''
                }
            },
            productionSeason (id) {
                const prod = this.$store.state.AgriResearch.commonObj.productionSeasonList.find(item => item.value === parseInt(id)) || this.$store.state.ExternalAgriResearch.agriResearchCommonObj.productionSeasonList.find(item => item.value === parseInt(id))
                return prod !== undefined ? this.$i18n.locale === 'en' ? prod.text_en : prod.text_bn : ''
            },
            checkOrg (type) {
                if (type === 1) {
                    return this.$t('request_testing_tag_without_field_certification_application.gov')
                } else {
                    return this.$t('request_testing_tag_without_field_certification_application.non_gov')
                }
            },
            getScheme (id) {
                const scheme = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.schemeList.find(item => item.value === parseInt(id)) || this.$store.state.AgriResearch.commonObj.schemeList.find(item => item.value === parseInt(id))
                if (this.$i18n.locale === 'bn') {
                    return scheme !== undefined ? scheme.text_bn : ''
                } else {
                    return scheme !== undefined ? scheme.text_en : ''
                }
            },
            LangCheck (bn, en) {
                return this.$i18n.locale === 'en' ? en : bn
            },
            addreassMaker (data) {
                return this.holding(data) + ', ' + data.village_name + ', ' + data.post_office_name + this.unionName(data.union_id) + this.upazilaName(data.upazilla_id) + this.districtName(data.district_id)
                // data.a_village_name + ', ' + data.a_holding_number_en + ', ' + data.a_post_office_name
            },
            addreassMaker2 (data) {
                return this.holding2(data) + ', ' + data.a_village_name + ', ' + data.a_post_office_name + this.unionName(data.a_union_id) + this.upazilaName(data.a_upazilla_id) + this.districtName(data.a_district_id)
                // data.a_village_name + ', ' + data.a_holding_number_en + ', ' + data.a_post_office_name
            },
            districtName (id) {
                const data = this.$store.state.ExternalUserIrrigation.commonObj.districtList.find(item => item.value === id) || this.$store.state.commonObj.districtList.find(item => item.value === id)
                return data !== undefined ? this.$i18n.locale === 'en' ? data.text_en : data.text_bn : ''
            },
            getOfficeName (id) {
                const data = this.$store.state.commonObj.officeList.find(item => item.value === parseInt(id))
                if (this.$i18n.locale === 'bn') {
                    return data !== undefined ? data.text_bn : ''
                } else {
                    return data !== undefined ? data.text_en : ''
                }
            },
            getOfficeType (id) {
                const data = this.$store.state.commonObj.officeTypeList.find(item => item.value === parseInt(id))
                if (this.$i18n.locale === 'bn') {
                    return data !== undefined ? data.text_bn : ''
                } else {
                    return data !== undefined ? data.text_en : ''
                }
            },
            getOrgName (id) {
                const data = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(item => item.value === parseInt(id)) || this.$store.state.commonObj.organizationProfileList.find(newItem => newItem.value === parseInt(id))
                if (this.$i18n.locale === 'bn') {
                    return data !== undefined ? data.text_bn : ''
                } else {
                    return data !== undefined ? data.text_en : ''
                }
            },
            upazilaName (id) {
                const data = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.find(item => item.value === id) || this.$store.state.commonObj.upazilaList.find(item => item.value === id)
                return data !== undefined ? this.$i18n.locale === 'en' ? data.text_en : data.text_bn : ''
            },
            seedProcessingCentersList (id) {
                const seedNameList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedProcessingCentersList.find(seed => seed.value === parseInt(id)) || this.$store.state.AgriResearch.commonObj.seedProcessingCentersList.find(newItem => newItem.value === parseInt(id))
                if (this.$i18n.locale === 'bn') {
                    return seedNameList !== undefined ? seedNameList.text_bn : ''
                } else {
                    return seedNameList !== undefined ? seedNameList.text_en : ''
                }
            },
            unionName (id) {
                const data = this.$store.state.ExternalUserIrrigation.commonObj.unionList.find(item => item.value === id) || this.$store.state.commonObj.unionList.find(item => item.value === id)
                return data !== undefined ? this.$i18n.locale === 'en' ? data.text_en : data.text_bn : ''
            },
            holding (data) {
                if (this.$i18n.locale === 'bn') {
                    return data.holding_number_bn
                } else {
                    return data.holding_number
                }
            },
            holding2 (data) {
                if (this.$i18n.locale === 'bn') {
                    return data.a_holding_number_bn
                } else {
                    return data.a_holding_number
                }
            },
            async getApplication () {
                this.loading = true
                const result = await RestApi.getData(agriResearchServiceBaseUrl, `${getSingleApplication}/${this.id}`)
                if (result.success) {
                    this.details = result.data
                    this.loading = false
                }
                this.loading = false
            }
        }
    }
</script>
<style>
    .hidden_header {
        display: none
    }
    .card-border {
        border: 1px solid #b9bdc1;
        box-shadow: 1px 1px 6px -1px grey;
        background-color: #dee2e6;
    }
    [dir=ltr][mode=light] .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 10px;
    }
    .report-name{
        font-weight: bold !important;
        text-transform: uppercase;
    }
    .my-btn{
        padding: 2px !important;
    }
</style>
