<template>
  <b-container fluid>
      <b-overlay :show="loadData">
        <b-row>
          <b-col lg="12" sm="12">
              <div style="border: 2px solid;margin:10px;padding:10px;width:660px;margin:auto">
                <h3 class="text-center">{{$t('certify_form.seed_certification_agency')}}</h3>
                <hr/>
                <table style="width:100%" class="text-black">
                  <tr>
                    <th style="width:30%">{{$t('certify_form.application_number')}}</th>
                    <th style="width:5%">:</th>
                    <th style="width:20%">{{$n(detail.applicationId, { useGrouping: false })}}</th>
                    <th style="width:20%">{{$t('globalTrans.date')}}</th>
                    <th style="width:5%">:</th>
                    <th style="width:20%">{{detail.created_at | dateFormat}}</th>
                  </tr>
                </table>
                <br/>
                <table style="width:100%" class="text-black">
                  <tr>
                    <td style="width:30%">{{$t('certify_form.dealer_registration_number')}}</td>
                    <td style="width:5%">:</td>
                    <td>{{$n(registration_number.dealer_registration_number, { useGrouping: false })}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('testing_and_tag_issue.applicant_name')}}</td>
                    <td style="width:5%">:</td>
                    <td v-if="detail.applicantaddresstwo !== undefined">{{ ($i18n.locale==='bn') ? detail.applicantaddresstwo.applicant_name_bn : detail.applicantaddresstwo.applicant_name }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('certify_form.plot_location')}}</td>
                    <td style="width:5%">:</td>
                    <td>
                      <span>{{ detail.plotlocationtwo !== undefined ? getScheme(detail.plotlocationtwo.scheme_id) : ''}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{$t('research_seed.seed_name')}}</td>
                    <td style="width:5%">:</td>
                    <td v-if="detail.seedsourcetwo !== undefined">
                      {{ ($i18n.locale==='bn') ? detail.seedsourcetwo.seedname.seed_name_bn : detail.seedsourcetwo.seedname.seed_name  }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{$t('research_seed.seed_variety')}}</td>
                    <td style="width:5%">:</td>
                    <td v-if="detail.seedsourcetwo !== undefined">
                      {{ ($i18n.locale==='bn') ? detail.seedsourcetwo.seedvariety.seed_variety_name_bn : detail.seedsourcetwo.seedvariety.seed_variety_name }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{$t('research_seed.seed_class')}}</td>
                    <td style="width:5%">:</td>
                    <td v-if="detail.seedsourcetwo !== undefined">
                      {{ ($i18n.locale==='bn') ? detail.seedsourcetwo.seedclass.seed_class_name_bn : detail.seedsourcetwo.seedclass.seed_class_name }}
                    </td>
                  </tr>
                  <!-- <tr>
                    <td>{{$t('certify_form.seeds_shall_be_produced')}}</td>
                    <td style="width:5%">:</td>
                    <td>{{ ($i18n.locale==='bn') ? detail.seed_produce_class_name : detail.seed_produce_class_name }}</td>
                  </tr> -->
                  <tr>
                    <td>{{$t('certify_form.possible_date_harvesting')}}</td>
                    <td style="width:5%">:</td>
                    <td v-if="detail.flowers !== undefined">{{detail.flowers.possible_date_harvesting | dateFormat}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('certify_form.separation_distance')}}</td>
                    <td style="width:5%">:</td>
                    <td>{{ $n(firstInspection.separation_distance, { useGrouping: false }) }}</td>
                  </tr>
                  <!-- <tr>
                    <td>{{$t('certify_form.seed_borne_diseases')}}</td>
                    <td style="width:5%">:</td>
                    <td>{{ ($i18n.locale==='bn') ? detail.borne_diseases_bn : detail.borne_diseases }}</td>
                  </tr> -->
                  <tr>
                    <td>{{$t('research_seed.is_final_report')}}</td>
                    <td style="width:5%">:</td>
                    <td>
                        {{$t('globalTrans.yes')}}
                    </td>
                  </tr>
                  <tr>
                    <td>{{this.$t('research_seed.possible_seed_yield')}}</td>
                    <td style="width:5%">:</td>
                    <td>{{ $n(parseFloat(secondInspection.second_seed_yeild)) }}</td>
                  </tr>
                  <tr>
                    <td>{{this.$t('research_seed.representative_present')}}</td>
                    <td style="width:5%">:</td>
                    <td>
                      <slot v-if="parseInt(inspection_report.representative_present) === 0">
                        {{$t('globalTrans.yes')}}
                      </slot>
                      <slot v-else>
                        {{$t('globalTrans.no')}}
                      </slot>
                    </td>
                  </tr>
                  <tr>
                    <td>{{this.$t('certify_form.is_this_seed_ensuring_field_inspection')}}?</td>
                    <td style="width:5%">:</td>
                    <td>{{ parseInt(firstInspection.ensuring_seed_quality) === 0 ? $t('globalTrans.yes') : $t('globalTrans.no') }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('research_seed.hectors_of_field')}}</td>
                    <td style="width:5%">:</td>
                    <td>
                      <slot v-if="firstInspection">
                        {{$n(parseFloat(firstInspection.hectors_of_field))}}
                      </slot>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td>{{$t('certify_form.inspection_quality')}}</td>
                    <td style="width:5%">:</td>
                    <td>{{ detail.ensures_field_inspection_quality }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('certify_form.inspection_remarks')}}</td>
                    <td style="width:5%">:</td>
                    <td>{{ $i18n.local === 'en' ? detail.remark : detail.remark_bn }}</td>
                  </tr> -->
                  <tr>
                    <td>{{$t('research_seed.inspection_date')}}</td>
                    <td style="width:5%">:</td>
                    <td>
                      <slot v-if="thirdInspection">
                        <!-- {{ thirdInspection.inspection_date }} -->
                        {{ inspection_report.inspection_date | dateFormat}}
                      </slot>
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;&nbsp;</td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
                <b-card v-if="secondInspection.number_of_trees.length > 0">
                  <table class="table" style="width:100%" border="1">
                      <thead class="thead">
                        <tr>
                          <th style="width:30%">{{$t('research_seed.sl_number')}}</th>
                          <th style="width:30%">{{$t('research_seed.second_tree_sheaves')}}</th>
                          <th style="width:30%">{{$t('research_seed.amount')}}</th>
                        </tr>
                      </thead>
                      <tr v-for="(detail,index) in secondInspection.number_of_trees" :key="index" >
                        <td>
                          {{$n(detail.second_tree_counts, { useGrouping: false })}}
                        </td>
                        <td>
                          {{getNameData(detail.second_tree_sheaves)}}
                        </td>
                        <td>
                          {{$n(detail.amount, { useGrouping: false })}}
                        </td>
                      </tr>
                  </table>
                </b-card>
                <b-row style="margin-top: 10% !important">
                  <b-col lg="5">
                    <span class="font-weight-bold">{{$t('globalTrans.date')}}</span>: . . . . . . . . . . . . . . . .
                  </b-col>
                  <b-col lg="7" class="text-center">
                    <hr style="margin-bottom: 0rem !important;border-top: 1px solid #000 !important">
                    <span class="font-weight-bold">{{ $t('application_details.signature') }}</span>
                  </b-col>
                </b-row>
              </div>
          </b-col>
        </b-row>
      </b-overlay>
    </b-container>
</template>
<script>
import { getCertificate } from '../../../api/routes'
import ExportPdf from './export-pdf_details'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import researchTestingModal from '@/mixins/research-testing-modal'
import researchTestingHelper from '@/mixins/agri-research-helpers'
import moment from 'moment'
export default {
    mixins: [researchTestingModal, researchTestingHelper],
    name: 'Details',
    props: ['id'],
    data () {
        return {
            loadData: false,
            detail: '',
            mixtureSheavesList: [{
                value: 'Obnoxious weed',
                text: this.$i18n.locale === 'en' ? 'Obnoxious weed' : 'বিষাক্ত আগাছা'
            },
            {
                value: 'Other variety',
                text: this.$i18n.locale === 'en' ? 'Other variety' : 'অন্য ফসলের মিশ্র'
            },
            {
                value: 'Desease infested Plant',
                text: this.$i18n.locale === 'en' ? 'Desease infested Plant' : 'রোগ আক্রান্ত বীজ'
            },
            {
                value: 'Seed brone plant',
                text: this.$i18n.locale === 'en' ? 'Seed brone plant' : 'বীজ ব্রোন উদ্ভিদ'
            },
            {
                value: 'General Condition of the Crop',
                text: this.$i18n.locale === 'en' ? 'General Condition of the Crop' : 'ফসলের সাধারণ অবস্থা'
            }],
            secondInspection: {
              number_of_trees: []
            },
            firstInspection: [],
            thirdInspection: [],
            slOffset: 1,
            registration_number: '',
            inspection_report: ''
        }
    },
    created () {
        if (this.id) {
          this.loadData = true
          this.getPumpInformation()
        }
    },
    methods: {
        getDate (date) {
            return moment(date).format('L')
        },
        getNameData (id) {
          const obj = this.mixtureSheavesList.find(item => item.value === id)
          if (this.$i18n.locale === 'bn') {
            return obj.text
          } else {
            return obj.text
          }
        },
        getScheme (id) {
          const scheme = this.$store.state.AgriResearch.commonObj.schemeList.find(item => item.value === parseInt(id))
          if (this.$i18n.locale === 'bn') {
            return scheme !== undefined ? scheme.text_bn : this.detail.plotlocationtwo.mouja_scheme_name_bn
          } else {
            return scheme !== undefined ? scheme.text_en : this.detail.plotlocationtwo.mouja_scheme_name
          }
        },
        getYesNo (flag) {
            if (parseInt(flag)) {
                return this.$t('globalTrans.no')
            } else {
                return this.$t('globalTrans.yes')
            }
        },
        getPumpInformation () {
            RestApi.getData(agriResearchServiceBaseUrl, `${getCertificate}/${this.id}`).then(response => {
                if (response.success) {
                  this.detail = response.data
                    this.secondInspection = response.secondInspection
                    this.firstInspection = response.firstInspection
                    this.thirdInspection = response.thirdInspection
                    this.registration_number = response.registration_number
                    this.inspection_report = response.inspection_report
                }
                this.loadData = false
            })
        },
        pdfExport () {
            const reportTitle = this.$t('research_seed.seed_certificate_agency')
            ExportPdf.exportPdfDetails(reportTitle, this, this.detail, this.firstInspection, this.secondInspection, this.thirdInspection)
        }
    }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
